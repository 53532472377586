<template>
<section class="blank-page">
    <pageLoader v-if="ploader" :loadingText="'En cours de chargement'"/>
    <div class="page-header">
      <h3 class="page-title"> Ajouter un Utilisateur </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">e-Commerce</a></li>
          <li class="breadcrumb-item active" aria-current="page">Ajouter un Utilisateur</li>
        </ol>
      </nav>
    </div>
      <div class="row">
          <div class="col-md-12">
              <div class="card">

                <!-- snotify -->
                <!-- <button @click="showSnotify()">snotify</button>
                <simpleSnotifysuccess /> -->

                    <div class="card-body">
                        <!-- ajout utilisateur -->
                        <div class="row">
                          <div class="col-md-12">
                            <h3>Informations personnels</h3>
                            <form class="forms-sample" @submit.prevent="handleSubmit()">

                              <!-- pseudo -->
                              <b-form-group id="pseudo" label="Nom d'utilisateur" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="pseudo"
                                  :state="$v.pseudo.$dirty ? !$v.pseudo.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- email -->
                              <b-form-group id="email" label="email" label-for="example-input-1">
                                <b-form-input
                                type="email"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="email"
                                  :state="$v.email.$dirty ? !$v.email.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- password -->
                              <b-form-group id="password" label="Mot de passe" label-for="example-input-1">
                                <b-form-input
                                type="password"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="password"
                                  :state="$v.password.$dirty ? !$v.password.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- confirmation password -->
                              <b-form-group id="confirmPassword" label="Confirmer le mot de passe" label-for="example-input-1">
                                <b-form-input
                                type="password"
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="confirmPassword"
                                  :state="$v.confirmPassword.$dirty ? !$v.confirmPassword.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Les mots de passe ne sont pas identiques
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- roles -->
                              <b-form-group id="roles" label="Rôles" label-for="example-input-1">
                              <b-form-checkbox-group
                              v-model="selectedRole" 
                              :options="Roleoptions"
                              value-field="item"
                              text-field="name"
                              :state="$v.selectedRole.$dirty ? !$v.selectedRole.$error : null"
                              aria-describedby="input-1-live-feedback"
                              multiple
                              >
                              </b-form-checkbox-group>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group> 

                              <!-- firstname -->
                              <b-form-group id="firstname" label="Nom" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="firstname"
                                  :state="$v.firstname.$dirty ? !$v.firstname.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- lastname -->
                              <b-form-group id="lastname" label="Prénom" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="lastname"
                                  :state="$v.lastname.$dirty ? !$v.lastname.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <!-- tel -->
                              <b-form-group id="tel" label="Numéro téléphone" label-for="example-input-1">
                                <b-form-input
                                  id="example-input-1"
                                  name="example-input-1"
                                  v-model="tel"
                                  :state="$v.tel.$dirty ? !$v.tel.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <h3>Adresse</h3>
                              <b-form-group id="address" label="Adresse" label-for="address">
                                <b-form-input
                                  id="address"
                                  name="address"
                                  v-model="address"
                                  :state="$v.address.$dirty ? !$v.address.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                ></b-form-input>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                  Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group id="address2" label="Adresse Complémentaire" label-for="address2">
                                <b-form-input
                                  id="address2"
                                  name="address2"
                                  v-model="address2"
                                ></b-form-input>
                              </b-form-group>

                              <b-form-group id="city" label="Ville" label-for="city">
                                <b-form-input
                                  id="city"
                                  name="city"
                                  v-model="city"
                                ></b-form-input>
                              </b-form-group>

                              <b-form-group id="country" label="Pays" label-for="country">
                                <!-- <b-form-input
                                  id="country"
                                  name="country"
                                  v-model="country"
                                ></b-form-input> -->
                                <b-form-select  id="country" name="country" v-model="country"
                                  :state="$v.country.$dirty ? !$v.country.$error : null"
                                  aria-describedby="input-1-live-feedback"
                                  class="mb-3">
                                      <b-form-select-option
                                      v-for="(country,country_id) in countries" :key="country_id"
                                      :value="country.translations.fr"
                                      >
                                      <img :src="country.flags.png" alt="">{{country.translations.fr}}
                                      </b-form-select-option>
                                  </b-form-select>
                                <b-form-invalid-feedback id="input-1-live-feedback">
                                Ce champ est obligatoire.
                                </b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group id="postal_code" label="Code postal" label-for="postal_code">
                                <b-form-input
                                  id="postal_code"
                                  name="postal_code"
                                  v-model="postal_code"
                                ></b-form-input>
                              </b-form-group>

                              <h3>Activation</h3>
                              <b-form-group id="isactive" label="Activer le compte" label-for="isactive">
                                <b-form-checkbox
                                  id="checkbox-1"
                                  v-model="isactive"
                                  name="checkbox-1"
                                >
                                </b-form-checkbox>
                              </b-form-group>

                              <div class="d-flex">
                                <b-button type="submit" variant="success" class="mr-2">Valider</b-button>
                                <b-button variant="dark" class="mr-2" @click="$router.go(-1) && showSnotify()">Annuler</b-button>
                                <!-- <alert-prompt buttonTitle="Annuler" text="Annuler l'ajout du produit" /> -->
                              </div>
                              
                            </form>
                          </div>
                        </div>
                      <!-- Modal Ends -->
                    </div>
                  </div>
          </div>
      </div>
  </section>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required,  sameAs, email} from 'vuelidate/lib/validators'
import pageLoader from '../../components/pageLoader.vue'

const axios= require('axios').default
export default {
    name: 'addUser',
    components:{
      pageLoader
    },
    mixins: [validationMixin],
    data () {
        return {
            ploader:true,
            ulidcompany:'',
            pseudo:'',
            email:'',
            password:'',
            confirmPassword:'',
            // roles:'',
            firstname:'',
            lastname:'',
            tel:'',
            address:'',
            address2:'',
            city:'',
            state:'Vide',
            country:'',
            postal_code:'',
            datePublished:'',
            dateModified:'',
            selectedRole:[],
            Roleoptions: [
              {item: 'ROLE_ADMIN_DASHBOARD', name: 'Administrateur Dashboard'},
              {item: 'ROLE_USER_DASHBOARD', name: 'Utilisateur Dashboard'},
              {item: 'ROLE_USER', name: 'Utilisateur'}
            ],
            isactive:true,
            countries:[]
        }
    },
    validations: {
        // ulidcompany: {
        //     required
        // },
        pseudo: {
            required
        },
        email: {
            required,
            email
        },
        password: {
            required
        },
        confirmPassword: {
          sameAs: sameAs('password')
        },
        selectedRole: {
            required
        },
        firstname: {
            required
        },
        lastname: {
            required
        },
        tel: {
            required
        },
        datePublished: {
            required
        },
          address: {
              required
          },
        country: {
            required
        },
    },
    methods: {
        handleSubmit() {
            this.$v.$touch()
        if (this.$v.$anyError) {
          return
        }
        this.ploader= true
        if(!this.ulid) {
          axios.post('api/users',
            {
                ulidcompany: this.ulidcompany,
                pseudo: this.pseudo,
                email: this.email,
                password: this.password,
                roles: this.selectedRole,
                firstname: this.firstname,
                lastname: this.lastname,
                tel: this.tel,
                isactive: this.isactive,
                newsletter: true,
                address: {
                  mainaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  },
                  billingaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  },
                  deliveryaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  }
                }
            },
            {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }

            })
            .then(res=> {
              console.log(res)
              this.$router.push({
                name:'user'
              })
              this.$snotify.info("Ajouté avec succès")
            })
            .catch(err=>{
              this.ploader=false
              if(err.response.data.includes("pseudo")){
                alert("Le nom d'utilisateur est déjà utilisé ou comporte une erreur")
              }
              else if(err.response.data.includes("email")){
                alert("L'adresse email est déjà utilisée ou comporte une erreur")
              }
              else{
                alert("Votre inscription comporte une erreur")
              }
              })
        }
        else{
          alert('PUT')
          axios.put('api/users/'+this.ulid,
          {
            ulidcompany: this.ulidcompany,
                pseudo: this.pseudo,
                email: this.email,
                password: this.password,
                roles: this.selectedRole,
                firstname: this.firstname,
                lastname: this.lastname,
                tel: this.tel,
                isactive: this.isactive,
                address: {
                  mainaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  },
                  billingaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  },
                  deliveryaddress:{
                    line1: this.address,
                    line2: this.address2,
                    city: this.city,
                    state: this.state,
                    country: this.country,
                    postal_code: this.postal_code
                  }
                },
                // datepublished: this.datePublished
          },
          {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
          })
          .then(()=> {
            this.$router.push({
              name:'user'
            })
          })
          .catch(errPutUser=>{
            if(errPutUser.response.data.includes("pseudo")){
                alert("Le nom d'utilisateur est déjà utilisé ou comporte une erreur")
              }
              else if(errPutUser.response.data.includes("email")){
                alert("L'adresse email est déjà utilisée ou comporte une erreur")
              }
              else{
                alert("Votre inscription comporte une erreur")
              }
            // console.log(errPutUser)
            })
        }
            
        },
        getUsers() {
          this.ploader =true
          if(this.ulid) {
            axios.get(`api/users/${this.ulid}`,
            {
              headers: { Authorization: 'Bearer ' + localStorage.getItem('main-token') }
            })
            .then(resUser=> {
              this.pseudo= resUser.data.pseudo,
              this.email= resUser.data.email,
              this.password= resUser.data.password,
              this.selectedRole= resUser.data.roles,
              this.firstname= resUser.data.firstname,
              this.lastname= resUser.data.lastname,
              this.tel= resUser.data.tel,
              this.datePublished= resUser.data.datepublished,
              this.address = resUser.data.address.mainaddress.line1,
              this.address2 = resUser.data.address.mainaddress.line2,
              this.city = resUser.data.address.mainaddress.city,
              this.state = resUser.data.address.mainaddress.state,
              this.country = resUser.data.address.mainaddress.country,
              this.postal_code = resUser.data.address.mainaddress.postal_code
              this.isactive = resUser.data.isactive
              this.ploader = false
            })
            .catch(erruser=> console.log(erruser))
          }
          else{
            console.log('POST');
          }
          },
          getCountries(){
              axios.get('https://restcountries.com/v2/all')
              .then(resCountries => {
                  this.countries = resCountries.data
                  console.log(this.countries);
              })
              .catch(errCountries => {
                  errCountries
              })
          }
    },
    mounted() {
      this.ulid= this.$route.params.ulid
      this.getCountries()
      if (this.ulid) {
        this.getUsers()
      }else{
        this.ploader=false
      }
      console.log(this.ulid)
      this.datePublished = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
      this.dateModified = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '')
    }
}
</script>
